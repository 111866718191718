<template>
  <v-form>
    <v-card color="#F5F5F5" flat>
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
            >ปริญญาเอก</span
          >
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-btn
                @click="goToCreate()"
                color="#2AB3A3"
                dark
                style="height: 48px"
              >
                + เพิ่มสาขา ปริญญาเอก
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="mt-n2">
          <v-card class="pa-6" width="100%" flat>
            <v-row>
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  placeholder="ค้นหาหลักสูตร"
                  outlined
                  dense
                  v-model="search"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-select
                  outlined
                  dense
                  :items="[
                    'สถานะทั้งหมด',
                    'เปิดใช้งาน',
                    'ปิดใช้งาน',
                    'ฉบับร่าง',
                  ]"
                  v-model="statusSearch"
                  @change="searchSelectStatus(statusSearch)"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headersBrand"
              :items="items"
              :search="search"
              disable-pagination
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="
                    item.status == 'เปิดใช้งาน'
                      ? '#ECFDF3'
                      : item.status == 'ฉบับร่าง'
                      ? '#F0F9FF'
                      : '#F2F4F7'
                  "
                  dark
                >
                  <span
                    v-if="item.status == 'เปิดใช้งาน'"
                    style="color: #027a48"
                  >
                    {{ item.status }}
                  </span>
                  <span
                    v-if="item.status == 'ปิดใช้งาน'"
                    style="color: #344054"
                  >
                    {{ item.status }}
                  </span>
                  <span v-if="item.status == 'ฉบับร่าง'" style="color: #026aa2">
                    {{ item.status }}
                  </span>
                </v-chip>
              </template>
              <template v-slot:[`item.img_url`]="{ item }">
                <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
                <draggable
                  :list="props.items"
                  tag="tbody"
                  @change="DragItems(props.items)"
                >
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td v-if="isWindowWidthGreaterThan768">
                      <v-icon small class="page__grab-icon">
                        mdi-arrow-all
                      </v-icon>
                    </td>
                    <td class="text-start">{{ item.index }}</td>
                    <td class="text-start">
                      <v-img
                        :src="item.img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </td>
                    <td class="text-start">{{ item.title_th }}</td>
                    <td class="text-start">{{ item.title_en }}</td>
                    <td class="text-center">
                      <v-chip
                        :color="
                          item.status == 'เปิดใช้งาน'
                            ? '#ECFDF3'
                            : item.status == 'ฉบับร่าง'
                            ? '#F0F9FF'
                            : '#F2F4F7'
                        "
                        dark
                      >
                        <span
                          v-if="item.status == 'เปิดใช้งาน'"
                          style="color: #027a48"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ปิดใช้งาน'"
                          style="color: #344054"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ฉบับร่าง'"
                          style="color: #026aa2"
                        >
                          {{ item.status }}
                        </span>
                      </v-chip>
                    </td>

                    <td class="text-start">
                      <v-row justify="center">
                        <v-icon @click="ViewNews(item)"
                          >mdi-content-copy</v-icon
                        >
                        <v-icon @click="UpdateNews(item)" class="mx-2"
                          >mdi-pencil</v-icon
                        >
                        <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                      </v-row>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      statusSearch: "สถานะทั้งหมด",

      tab: null,
      typeCheck: 0,
      //       createdAt: (...)
      // facualty: (...)
      // facualtyId: (...)
      // full_desc: (...)
      // id: (...)
      // img_url: (...)
      // level: (...)
      // levelId: (...)
      // short_desc: (...)
      // index: 1
      // title: (...)
      // updatedAt: (...)
      headersBrand: [],
      headersBrand1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูปหลักสูตร", value: "img_url", align: "start" },
        { text: "ชื่อหลักสูตร (TH)", value: "title_th", align: "start" },
        { text: "ชื่อหลักสูตร (EN)", value: "title_en", align: "start" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrand2: [
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูปหลักสูตร", value: "img_url", align: "start" },
        { text: "ชื่อหลักสูตร (TH)", value: "title_th", align: "start" },
        { text: "ชื่อหลักสูตร (EN)", value: "title_en", align: "start" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      items1: [],
      itemsAll: [],
      search: "",
      index: 0,
      user: "",
      loading: false,
      imgUrl: "",

      res2data: [],
      res4data: [],
      res6data: [],
      res8data: [],
      res10data: [],
      res12data: [],
      res14data: [],
      res16data: [],
      res18data: [],
      res20data: [],
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    // this.getAll1();
    document.title = "หลักสูตร (ป.เอก) - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersBrand = this.headersBrand1;
    } else {
      this.headersBrand = this.headersBrand2;
    }
  },

  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("DragItems", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/courses/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.getAll();

      this.loading = false;
    },

    searchSelectStatus(val) {
      let AllItems = this.itemsAll;
      let items1 = AllItems.filter((x) => x.status === val);
      if (this.statusSearch == "สถานะทั้งหมด") {
        this.items = AllItems;
      } else {
        this.items = items1;
      }
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },

    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courses?levelId=13`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      this.itemsAll = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/courses/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
          // this.getAll1();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateCoursePhD");
    },

    UpdateNews(val) {
      localStorage.setItem("CourseData", Encode.encode(val));
      this.$router.push("EditCoursePhD");
    },

    async ViewNews(val) {
      console.log("val", val);
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courses/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;
      console.log("duplicateData", duplicateData);

      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/courses`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);

      const response2 = await this.axios.get(
        `${process.env.VUE_APP_API}/coursesOffereds?courseId=${val.id}`,
        auth
      );
      this.res2data = response2.data.data;
      console.log("this.res2data", this.res2data);

      await this.res2data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response3 = await this.axios.post(
          `${process.env.VUE_APP_API}/coursesOffereds`,
          element,
          auth
        );
        // console.log("response3", response3.data.data);
      });

      const response4 = await this.axios.get(
        `${process.env.VUE_APP_API}/majorNews?courseId=${val.id}`
      );
      this.res4data = response4.data.data;
      console.log("this.res4data", this.res4data);

      await this.res4data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response5 = await this.axios.post(
          `${process.env.VUE_APP_API}/majorNews`,
          element,
          auth
        );
        // console.log("response5", response5.data.data);
      });

      const response6 = await this.axios.get(
        `${process.env.VUE_APP_API}/subjectTypeNews?courseId=${val.id}`
      );
      this.res6data = response6.data.data;
      console.log("this.res6data", this.res6data);

      await this.res6data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response7 = await this.axios.post(
          `${process.env.VUE_APP_API}/subjectTypeNews`,
          element,
          auth
        );
        // console.log("response7", response7.data.data);
      });

      const response8 = await this.axios.get(
        `${process.env.VUE_APP_API}/subjectStructures?courseId=${val.id}`
      );
      this.res8data = response8.data.data;
      console.log("this.res8data", this.res8data);

      await this.res8data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response9 = await this.axios.post(
          `${process.env.VUE_APP_API}/subjectStructures`,
          element,
          auth
        );
        // console.log("response9", response9.data.data);
      });

      const response10 = await this.axios.get(
        `${process.env.VUE_APP_API}/studyFees?type=1&&courseId=${val.id}`
      );
      this.res10data = response10.data.data;
      console.log("this.res10data", this.res10data);

      await this.res10data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response11 = await this.axios.post(
          `${process.env.VUE_APP_API}/studyFees`,
          element,
          auth
        );
        // console.log("response11", response11.data.data);
      });

      const response12 = await this.axios.get(
        `${process.env.VUE_APP_API}/studyFees?type=2&&courseId=${val.id}`
      );
      this.res12data = response12.data.data;
      console.log("this.res12data", this.res12data);

      await this.res12data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response13 = await this.axios.post(
          `${process.env.VUE_APP_API}/studyFees`,
          element,
          auth
        );
        // console.log("response13", response13.data.data);
      });

      const response14 = await this.axios.get(
        `${process.env.VUE_APP_API}/courses/form6/${val.id}`,
        auth
      );
      console.log("response14", response14.data.form6);
      delete response14.data.form6.id;
      let duplicateData6 = response14.data.form6;
      console.log("duplicateData6", duplicateData6);

      await duplicateData6.semesters.forEach(async (element) => {
        delete element.id;
        delete element.courseId;
      });
      await duplicateData6.study_day_times.forEach(async (element) => {
        delete element.id;
        delete element.courseId;
      });

      const data6 = {
        ...duplicateData6,
        courseId: response1.data.data.id,
      };

      console.log("data6", data6);

      setTimeout(async () => {
        if (response1.data.data.id) {
          const response15 = await this.axios.post(
            `${process.env.VUE_APP_API}/courses/form6`,
            data6,
            auth
          );
          console.log("response15", response15);
        }
      }, 1000);

      const response16 = await this.axios.get(
        `${process.env.VUE_APP_API}/expectedLearningOutcomes?courseId=${val.id}`
      );
      this.res16data = response16.data.data;
      console.log("this.res16data", this.res16data);

      await this.res16data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response17 = await this.axios.post(
          `${process.env.VUE_APP_API}/expectedLearningOutcomes`,
          element,
          auth
        );
        // console.log("response17", response17.data.data);
      });

      const response18 = await this.axios.get(
        `${process.env.VUE_APP_API}/faqs?courseId=${val.id}`
      );
      this.res18data = response18.data.data;
      console.log("this.res18data", this.res18data);

      await this.res18data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response19 = await this.axios.post(
          `${process.env.VUE_APP_API}/faqs`,
          element,
          auth
        );
        // console.log("response19", response19.data.data);
      });

      const response20 = await this.axios.get(
        `${process.env.VUE_APP_API}/reviews?courseId=${val.id}`
      );
      this.res20data = response20.data.data;
      console.log("this.res20data", this.res20data);

      await this.res20data.forEach(async (element) => {
        element.courseId = response1.data.data.id;
        delete element.id;
        const response21 = await this.axios.post(
          `${process.env.VUE_APP_API}/reviews`,
          element,
          auth
        );
        // console.log("response21", response21.data.data);
      });

      const response22 = await this.axios.get(
        `${process.env.VUE_APP_API}/atmospheres/${val.id}`,
        auth
      );
      console.log("response22", response22.data.data);
      response22.data.data ? delete response22.data.data.id : "";
      let pictureData = response22.data.data;
      response22.data.data
        ? (pictureData.courseId = response1.data.data.id)
        : "";
      console.log("pictureData", pictureData);

      const response23 = await this.axios.post(
        `${process.env.VUE_APP_API}/atmospheres`,
        pictureData,
        auth
      );
      console.log("response23", response23);

      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAll();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },
  },
};
</script>